.text_decoration_none {
    text-decoration: none;
}

.text_align_justify {
    text-align: justify;
}

.heading {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    font-family: Poppins, sans-serif !important;
    font-size: 38px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.cta_heading {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-family: Poppins, sans-serif !important;
    color: #000 !important;
    font-size: 20px !important;
    font-weight: lighter !important;
    text-align: center !important;
}

@media (max-width: 321px) {
    .heading {
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        padding-left: 20px !important;
        font-family: Poppins, sans-serif !important;
        font-size: 1.1rem !important;
        font-weight: bold !important;
        text-align: left !important;
    }
}