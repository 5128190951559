.text_decoration_none {
    text-decoration: none;
}

.text_align_justify {
    text-align: justify;
}

.heading {
    padding-top: 40px !important;
    padding-bottom: 10px !important;
    font-family: Poppins, sans-serif !important;
    color: #000 !important;
    font-size: 38px !important;
    font-weight: 500 !important;
    text-align: center !important;
}

.cta_heading {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    font-family: Poppins, sans-serif !important;
    color: #000 !important;
    font-size: 20px !important;
    font-weight: lighter !important;
    text-align: center !important;
}

.marginX {
    margin-top: 30px;
    margin-bottom: 85px;
}

@media (max-width: 900px) {
    .marginX {
        margin-top: 30px;
        margin-bottom: 130px;
    }

}