.container {
    justify-content: space-around !important;
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.heading {
    border-bottom: 2px solid !important;
    text-transform: uppercase !important;
    white-space: nowrap !important;
}

.subheading {
    text-transform: uppercase !important;
    white-space: nowrap !important;
}

.links {
    color: #000 !important;
    text-decoration: none !important;
}

.elevate {
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
    box-shadow: 0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%), 0px 6px 30px 5px rgb(0 0 0 / 12%) !important;
}