.container {
    margin-top: 80px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.container_input {
    margin: 8px !important;
}

legend {
    position: inherit;
    text-align: center;
    margin-bottom: 80px;
    margin-top: -15px;
}