.text_decoration_none {
    text-decoration: none;
}

.text_align_justify {
    text-align: justify;
}

.heading {
    padding-top: 40px !important;
    padding-bottom: 20px !important;
    font-family: Poppins, sans-serif !important;
    color: #000 !important;
    font-size: 38px !important;
    font-weight: 500 !important;
    text-align: center !important;
}